var swiper = new Swiper('.clients-slider', {
  direction: 'horizontal',
  slidesPerView: 3,
  centeredSlides: true,
  centerInsufficientSlides: true,
  autoplay: true,
  breakpoints: {
    768: {
      slidesPerView: 4,
      autoplay: false,
      centeredSlides: false,
      centerInsufficientSlides: false,
    },
  },
});

var pagesContainer = new Swiper('.resources-slider', {
  slidesPerView: 1,
  preventInteractionOnTransition: true,
  simulateTouch: false,
  allowTouchMove: false,
  effect: 'fade',
  fadeEffect: {
    crossFade: true,
  },
});

$('.resources-link').on('click', function () {
  $(this).addClass('ativo').siblings().removeClass('ativo');
  pagesContainer.slideTo($('.resources-link').index($(this)));
});

var swiper = new Swiper('.resources-slider__inner', {
  direction: 'horizontal',
  loop: false,
  centeredSlides: true,
  slidesPerView: 1,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

var swiper = new Swiper('.resources-nav__slider', {
  direction: 'horizontal',
  loop: false,
  slidesPerView: 3,
  centeredSlides: true,
  centerInsufficientSlides: true,
  spaceBetween: 35,
  initialSlide: 1,
  breakpoints: {
    768: {
      initialSlide: 3,
    },
    768: {
      slidesPerView: 6,
      direction: 'vertical',
      preventInteractionOnTransition: true,
      simulateTouch: false,
      centeredSlides: false,
      centerInsufficientSlides: false,
      spaceBetween: 0,
      initialSlide: 0,
    },
  },
});

(function () {
  $(window).scroll(function () {
    toggleBackToTop();
  });

  function toggleBackToTop() {
    if ($(window).width() <= 576) {
      var offset = 0;
    } else {
      var offset = 1,
        scrollTop = 0,
        $btn = $('.wpp-float');

      scrollTop = $(window).scrollTop();

      if (scrollTop >= offset) {
        $btn.fadeIn();
      } else {
        $btn.fadeOut();
      }
    }
  }
})();
